import skate from 'skatejs';
import {onChildrenChange} from '../internal/detect-children-change';
import jQuery from 'jquery';

export function createFormsComponentBody(type) {

    let unsub;

    return {
        type: skate.type.CLASSNAME,
        attached: function(el) {

            onChildrenChange(el,(unsubscribe) => {

                // Store function that stops subscription
                unsub = unsubscribe;

                const innerCheckboxList = jQuery(`input[type=${type}]`, el);

                innerCheckboxList.each(function (i, radio) {
                    jQuery('<span class="aui-form-glyph"></span>').insertAfter(radio);
                });

                const isInsertedAfterChange = innerCheckboxList.length > 0;
                if (isInsertedAfterChange) {
                    unsub();
                }
            });
        },
        detached: function(el) {
            jQuery('.aui-form-glyph', el).remove();
            if (unsub) {
                unsub();
            }
        }
    }
}

function isChildrenChanged(mutationList) {
    for (const mutation of mutationList) {
        if (mutation.type === 'childList') {
            return true;
        }
    }
    return false;
}

/***
 * Executes callback every time element children change. Called once initially.
 *
 * ***Make sure to use unsubscribe callback to free resources occupied for detection***
 *
 * @param element Element whose children should be monitored
 * @param callback Function to be called when children change happened. Gets unsubscribe function as argument.
 */
export function onChildrenChange(element, callback) {

    let isCompleteOnInit = false;

    callback(() => { isCompleteOnInit = true });

    if (isCompleteOnInit) {
        return;
    }

    const observer = new MutationObserver((mutationList) => {
        if (isChildrenChanged(mutationList)) {
            callback(() => observer.disconnect());
        }
    });

    observer.observe(element, { childList: true });
}

import {I18n} from './i18n';

const CLOSE_BUTTON_CLASS = 'aui-close-button';
const CLOSE_BUTTON_CLASS_SELECTOR = `.${CLOSE_BUTTON_CLASS}`;
const CLOSE_BUTTON = `<button type="button" class="${CLOSE_BUTTON_CLASS}" aria-label="${I18n.getText('aui.words.close')}">`;

export {
    CLOSE_BUTTON,
    CLOSE_BUTTON_CLASS,
    CLOSE_BUTTON_CLASS_SELECTOR
};

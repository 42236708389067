import $ from './jquery';

import { getMessageLogger } from './internal/deprecation';


const deprecate = {
    'aria-expanded': getMessageLogger('Expander [aria-expanded] attribute', {
        removeInVersion: '10.0.0',
        sinceVersion: '9.9.0',
        deprecationType: 'ARIA ATTRIBUTE',
        extraInfo:
            'The "aria-expanded" attribute will no longer act as an indicator for the expander if it needs to be expanded. Please update the expander in accordance with AUI guidelines.',
    }),
    'replace-selector': getMessageLogger('Expander [data-replace-selector] attribute', {
        removeInVersion: '10.0.0',
        sinceVersion: '9.9.0',
        deprecationType: 'DATA ATTRIBUTE',
        extraInfo:
            'The attribute "data-replace-selector" will no longer have any functionality. It is recommended to update the expander following AUI guidelines.',
    }),
    'short-content': getMessageLogger('Expander [.aui-expander-short-content]', {
        removeInVersion: '10.0.0',
        sinceVersion: '9.9.0',
        deprecationType: 'EXPANDER CLASS (PATTERN)',
    }),
}

const getExpanderProperties = ($trigger) => {
    const $content = $(`#${$trigger.attr('aria-controls')}`);
    const isTriggerParent = $content.parent().is($trigger);

    /**
     * ! Deprecated since 9.9.0
     * delete at version 10.0.0
     */
    const $shortContent = isTriggerParent ? $trigger.find('.aui-expander-short-content') : null;
    if ($content.attr('aria-expanded')) {
        deprecate['aria-expanded']();
        if ($content.attr('aria-expanded') === 'true') {
            $content.addClass('expanded');
        }
        $content.removeAttr('aria-expanded');
    }
    /* end of depraction */

    return {
        $content,
        isTriggerParent,
        $shortContent,
        isExpanded: $content.hasClass('expanded'),
        height: $content.css('min-height'),
        isCollapsible: $trigger.data('collapsible') !== false,
        textReplacement: $trigger.attr('data-replace-text'),
    };
};

function expand($trigger) {
    const { $content, isTriggerParent, $shortContent, textReplacement } = getExpanderProperties($trigger);

    $trigger.attr('aria-expanded', 'true');
    $content.addClass('expanded');
    $content.removeAttr('hidden');
    replaceText($trigger, textReplacement);

    if (isTriggerParent) {
        $shortContent.hide();
    }

    $trigger.trigger('aui-expander-expanded');
}

function collapse($trigger) {
    const { $content, isTriggerParent, $shortContent, textReplacement } = getExpanderProperties($trigger);

    replaceText($trigger, textReplacement);
    $trigger.attr('aria-expanded', 'false');
    $content.removeClass('expanded');

    if (isTriggerParent) {
        $shortContent.show();
    }

    if ($content.outerHeight() === 0) {
        $content.attr('hidden', '');
    }

    $trigger.trigger('aui-expander-collapsed');
}


function init($trigger) {
    const { $content, isTriggerParent, isExpanded } = getExpanderProperties($trigger);

    /**
     * ! Deprecated since 9.9.0
     * delete at version 10.0.0
     */
    const selector = $trigger.data('replace-selector');
    if (selector) {
        deprecate['replace-selector']();
    }
    if ($content.attr('aria-expanded')) {
        deprecate['aria-expanded']();
        if ($content.attr('aria-expanded') === 'true') {
            $content.addClass('expanded');
        }
        $content.removeAttr('aria-expanded');
    }

    const $shortContent = isTriggerParent ? $trigger.find('.aui-expander-short-content') : null;
    if ($shortContent) {
        deprecate['short-content']();
    }
    /* end of depraction */

    if ($content.outerHeight() === 0) {
        !isExpanded && $content.attr('hidden', '');
    }
    $trigger.attr('aria-expanded', isExpanded ? 'true' : 'false');
}

const replaceText = ($trigger, textReplacement) => {
    if (!textReplacement) { return };

    /**
     * ! Deprecated since 9.9.0
     * delete at version 10.0.0
     */
    const selector = $trigger.data('replace-selector');
    if (selector) {
        deprecate['replace-selector']();
        const $deprecatedReplaceElement = $trigger.find(selector);
        $trigger.attr('data-replace-text', $deprecatedReplaceElement.text());
        $deprecatedReplaceElement.text(textReplacement);
        return;
    }
    /* end of depraction */

    const $deepestChild = $trigger.find(':not(:has(*)):first');
    const $replaceElement = $deepestChild.length ? $deepestChild : $trigger;
    $trigger.attr('data-replace-text', $replaceElement.text());
    $replaceElement.text(textReplacement);
};

const EXPANDER_EVENTS = {
    'aui-expander-invoke': function (event) {
        const $trigger = $(event.currentTarget)
        const { isCollapsible, isExpanded } = getExpanderProperties($trigger);

        if (isExpanded && isCollapsible) {
            $trigger.trigger('aui-expander-collapse');
        } else {
            $trigger.trigger('aui-expander-expand');
        }
    },

    'aui-expander-expand': function (event) {
        expand($(event.currentTarget))
    },

    'aui-expander-collapse': function (event) {
        collapse($(event.currentTarget));
    },

    'click.aui-expander': function (event) {
        const $target = $(event.currentTarget);
        $target.trigger('aui-expander-invoke', event.currentTarget);
    }
};

$(document).on(EXPANDER_EVENTS, '.aui-expander-trigger');
$(document).ready(function() {
    $('.aui-expander-trigger').each(function() {
        const $trigger = $(this);
        init($trigger);
    })
})

export default {
    'aui.landmarks.site': 'Site',
    'aui.landmarks.sidebar': 'Sidebar',
    'aui.landmarks.page': 'Page',
    'aui.landmarks.main': 'Main',
    'aui.header.home.link': 'Go to home page',
    'aui.dropdown.async.error': 'Error loading dropdown',
    'aui.dropdown.async.loading': 'Loading dropdown',
    'aui.words.expand': 'Expand',
    'aui.words.collapse': 'Collapse',
    'aui.words.add': 'Add',
    'aui.words.update': 'Update',
    'aui.words.delete': 'Delete',
    'aui.words.remove': 'Remove',
    'aui.words.cancel': 'Cancel',
    'aui.words.loading': 'Loading',
    'aui.words.close': 'Close',
    'aui.enter.value': 'Enter value',
    'aui.words.more': 'More',
    'aui.words.moreitem': 'More…',
    'aui.keyboard.shortcut.type.x': "Type ''{0}''",
    'aui.keyboard.shortcut.then.x': "then ''{0}''",
    'aui.keyboard.shortcut.or.x': "OR ''{0}''",
    'aui.sidebar.expand.tooltip': 'Expand sidebar ( [ )',
    'aui.sidebar.collapse.tooltip': 'Collapse sidebar ( [ )',
    'aui.validation.message.maxlength': 'Must be fewer than or equal to {0} characters',
    'aui.validation.message.minlength': 'Must be greater than or equal to {0} characters',
    'aui.validation.message.exactlength': 'Must be exactly {0} characters',
    'aui.validation.message.matchingfield': '{0} and {1} do not match.',
    'aui.validation.message.matchingfield-novalue': 'These fields do not match.',
    'aui.validation.message.doesnotcontain': 'Do not include the phrase {0} in this field',
    'aui.validation.message.pattern': 'This field does not match the required format',
    'aui.validation.message.required': 'This is a required field',
    'aui.validation.message.validnumber': 'Please enter a valid number',
    'aui.validation.message.min': 'Enter a value greater than or equal to  {0}',
    'aui.validation.message.max': 'Enter a value less than or equal to {0}',
    'aui.validation.message.dateformat': 'Enter a valid date',
    'aui.validation.message.minchecked': 'Tick at least {0,choice,0#0 checkboxes|1#1 checkbox|1<{0,number} checkboxes}.',
    'aui.validation.message.maxchecked': 'Tick at most {0,choice,0#0 checkboxes|1#1 checkbox|1<{0,number} checkboxes}.',
    'aui.checkboxmultiselect.clear.selected': 'Clear selected items',
    'aui.select.no.suggestions': 'No suggestions',
    'aui.select.number.suggestions': 'Found {0,choice,0#0 options|1#1 option|1<{0,number} options}.',
    'aui.select.new.suggestions': 'New suggestions added. Please use the up and down arrows to select.',
    'aui.select.new.value': 'new value',
    'aui.toggle.on': 'On',
    'aui.toggle.off': 'Off',
    'aui.messagecomponent.error.label': 'Error',
    'aui.messagecomponent.warning.label': 'Warning',
    'aui.messagecomponent.info.label': 'Information',
    'aui.messagecomponent.confirmation.label': 'Confirmation',
    'aui.messagecomponent.change.label': 'Change',
    'ajs.datepicker.localisations.day-names.sunday': 'Sunday',
    'ajs.datepicker.localisations.day-names.monday': 'Monday',
    'ajs.datepicker.localisations.day-names.tuesday': 'Tuesday',
    'ajs.datepicker.localisations.day-names.wednesday': 'Wednesday',
    'ajs.datepicker.localisations.day-names.thursday': 'Thursday',
    'ajs.datepicker.localisations.day-names.friday': 'Friday',
    'ajs.datepicker.localisations.day-names.saturday': 'Saturday',
    'ajs.datepicker.localisations.day-names-min.sunday': 'Sun',
    'ajs.datepicker.localisations.day-names-min.monday': 'Mon',
    'ajs.datepicker.localisations.day-names-min.tuesday': 'Tue',
    'ajs.datepicker.localisations.day-names-min.wednesday': 'Wed',
    'ajs.datepicker.localisations.day-names-min.thursday': 'Thu',
    'ajs.datepicker.localisations.day-names-min.friday': 'Fri',
    'ajs.datepicker.localisations.day-names-min.saturday': 'Sat',
    'ajs.datepicker.localisations.first-day': 0,
    'ajs.datepicker.localisations.is-RTL': false,
    'ajs.datepicker.localisations.month-names.january': 'January',
    'ajs.datepicker.localisations.month-names.february': 'February',
    'ajs.datepicker.localisations.month-names.march': 'March',
    'ajs.datepicker.localisations.month-names.april': 'April',
    'ajs.datepicker.localisations.month-names.may': 'May',
    'ajs.datepicker.localisations.month-names.june': 'June',
    'ajs.datepicker.localisations.month-names.july': 'July',
    'ajs.datepicker.localisations.month-names.august': 'August',
    'ajs.datepicker.localisations.month-names.september': 'September',
    'ajs.datepicker.localisations.month-names.october': 'October',
    'ajs.datepicker.localisations.month-names.november': 'November',
    'ajs.datepicker.localisations.month-names.december': 'December',
    'ajs.datepicker.localisations.show-month-after-year': false,
    'ajs.datepicker.localisations.year-suffix': ''
}

import skate from './internal/skate';


const DEFAULT_PLACEMENT_VALUE = 'bottom-end';
const PLACEMENT_OPTIONS = ['top-start', 'top-end', 'bottom-start', 'bottom-end'];

const setBadgedPlacement = (element, newValue, oldValue) => {
    const newPlacement = PLACEMENT_OPTIONS.includes(newValue) ? newValue : DEFAULT_PLACEMENT_VALUE;

    if (oldValue) {
        element.classList.remove(`aui-avatar-badged-${oldValue}`);
    }
    element.classList.add(`aui-avatar-badged-${newPlacement}`);
}

const AvatarBadged = skate('aui-avatar-badged', {
    attributes: {
        placement: {
            value: DEFAULT_PLACEMENT_VALUE,
            fallback(element, { newValue, oldValue}) {
                setBadgedPlacement(element, newValue, oldValue);
            },
        },
    },

    created: function(element) {
        element.classList.add('aui-avatar-badged');
    }
});

export { AvatarBadged };

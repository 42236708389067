const NAMESPACE = 'AJS';
const I18N_OBJECT_NAME = 'I18n';

function initNamespace() {
    if (typeof window[NAMESPACE] !== 'object') {
        window[NAMESPACE] = {};
    }
}

/**
 * Makes given value available globally under window[NAMESPACE][name] attribute.
 * Keep in mind that this is needed for p2-plugin where chunks of AUI
 * can be loaded separately.
 *
 * In order for global namespace to work in `aui.prototyping.js` and
 * `aui.prototyping.nodeps.js` the proper exports need to be added to:
 * `packages/core/entry/aui.batch.prototyping.js`
 *
 * @param {string} name Name of the attribute
 * @param {any} value Value to expose globally
 * @returns exposed value
 */
export default function globalize (name, value) {
    initNamespace();

    return window[NAMESPACE][name] = value;
}

export function putOnI18nIfAbsent (name, value) {
    initNamespace();

    if (typeof window[NAMESPACE][I18N_OBJECT_NAME] !== 'object') {
        window[NAMESPACE][I18N_OBJECT_NAME] = {};
    }

    if (typeof window[NAMESPACE][I18N_OBJECT_NAME][name] === 'undefined') {
        window[NAMESPACE][I18N_OBJECT_NAME][name] = value;
    }
}

import $ from './jquery';
import createHeader from './internal/header/create-header';
import * as deprecate from './internal/deprecation';
import globalize from './internal/globalize';

function findAndCreateHeaders () {
    $('.aui-header').each(function () {
        createHeader(this);
    });
}

$(findAndCreateHeaders);

const responsiveheader = {};
responsiveheader.setup = deprecate.fn(findAndCreateHeaders, 'responsiveheader.setup', {
    removeInVersion: '10.0.0',
    sinceVersion: '5.8.0',
    extraInfo: 'No need to manually initialise anymore as this is now a web component.'
});

globalize('responsiveheader', responsiveheader);

export default responsiveheader;

import template from 'skatejs-template-html';
import skate from './internal/skate';
import personImage from '../../../../../packages/docs/src/docs/images/avatar-person.svg';
import projectImage from '../../../../../packages/docs/src/docs/images/avatar-project.svg';

const DEFAULT_AVATAR_IMAGES = new Map([
    ['project', projectImage],
    ['user', personImage]
]);

const getElementWrapper = (element, elementFind) => element.querySelector(elementFind);
const containsBadge = (element) => element.querySelector('aui-avatar-badged') !== null;

const replaceClass = (element, newValue, oldValue) => {
    const elementWrapper = getElementWrapper(element, '.aui-avatar');

    elementWrapper.classList.remove(`aui-avatar-${oldValue}`);
    elementWrapper.classList.add(`aui-avatar-${newValue}`);
}

const getAvatarType = (element) => element.getAttribute('type') === 'project' ? 'project' : 'user';

const setDefaultAvatarImage = (element) => {
    if (element.hasAttribute('src')){
        setImageSrc(element, element.getAttribute('src'));
    } else {
        const avatarType = getAvatarType(element);
        const avatarSvg = DEFAULT_AVATAR_IMAGES.get(avatarType);
        setImageSrc(element, avatarSvg);
    }
}

const setImageAttr = (element, name, value) => getElementWrapper(element, 'img').setAttribute(name, value);
const setImageSrc = (element, src) => setImageAttr(element, 'src', src);
const setImageAlt = (element, alt) => setImageAttr(element, 'alt', alt);
const setImageTitle = (element, title) => setImageAttr(element, 'title', title);

const AvatarEl = skate('aui-avatar', {
    template: template(`
      <span class='aui-avatar'>
         <span class='aui-avatar-inner'>
            <img />
         </span>
         <content></content>
      </span>
   `),

    attributes: {
        size(element, { newValue , oldValue}) {
            replaceClass(element, newValue, oldValue);
        },
        type(element, { newValue, oldValue}) {
            replaceClass(element, newValue, oldValue);
            setDefaultAvatarImage(element);
        },
        src(element, { newValue: value }) {
            setImageSrc(element, value);
        },
        alt: {
            value: '',
            fallback(element, {newValue: value}) {
                setImageAlt(element, value);
            }
        },
        title(element, {newValue: value}) {
            setImageTitle(element, value);
        },
    },

    created: function(element) {
        element.className = 'aui-avatar';
        setDefaultAvatarImage(element);

        if (containsBadge(element)) {
            element.setAttribute('role', 'img')
        }
    }
});

export { AvatarEl };

import $ from './jquery';
import globalize from './internal/globalize';
import {fn} from './internal/deprecation';
import params from './params';

function populateParametersOrg (parameters) {
    if (!parameters) {
        parameters = params;
    }

    $('.parameters input').each(function () {
        var value = this.value;
        var id = this.title || this.id;

        if ($(this).hasClass('list')) {
            if (parameters[id]) {
                parameters[id].push(value);
            } else {
                parameters[id] = [value];
            }
        } else {
            parameters[id] = (value.match(/^(tru|fals)e$/i) ? value.toLowerCase() === 'true' : value);
        }
    });
}

const deprecation = {
    sinceVersion: '9.0.0',
    removeInVersion: '10.0.0'
};

const populateParameters = fn(populateParametersOrg, 'populateParameters', deprecation);
globalize('populateParameters', populateParameters);

export default populateParameters;

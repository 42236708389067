import $ from './jquery';
import './spinner';
import skate from './internal/skate';
import { fn } from './internal/deprecation';

/**
 * @typedef {Object} jQuerySpinnerConfig
 * @property {SpinnerSize} size - the size of the spinner.
 */

/**
 * @param {jQuerySpinnerConfig|String|Boolean} [firstArgs]
 * - when an object, it is a {jQuerySpinnerConfig}
 * - when a string, it represents the `size` the spinner should take.
 * - when boolean, the argument can take only FALSE, in this case, the spinner will be stopped.
 * @param {jQuerySpinnerConfig} [maybeArgs]
 * @deprecated since AUI 7.9.4. Use the `<aui-spinner>` web component directly.
 */
$.fn.spin = fn(function spinStart(firstArgs, maybeArgs) {
    let args = { size: 'small' };
    if (typeof maybeArgs === 'object') {
        args = $.extend(args, maybeArgs)
    }
    if (typeof firstArgs === 'object') {
        args = $.extend(args, firstArgs);
    }
    if (typeof firstArgs === 'string') {
        args.size = firstArgs;
    }
    if (typeof firstArgs === 'boolean' && firstArgs === false) {
        return this.spinStop();
    }

    return this.each(function() {
        if (!this || !this.nodeType) { return; }
        const $this = $(this);
        const data = $this.data();
        if (data) {
            const $spinnerDom = $('<aui-spinner filled></aui-spinner>');
            $spinnerDom.attr('size', args.size); // the web component handles validation
            $spinnerDom.css('color', args.color);

            $this.spinStop();
            $this.append($spinnerDom);
            // AUI-4819 - ensure web component is initialised synchronously.
            skate.init(this);

            data.spinner = $spinnerDom;
        }
    });
}, 'jQuery.fn.spin', {
    sinceVersion: '7.9.4',
    removeInVersion: '10.0.0',
    alternativeName: '<aui-spinner>'
});

/**
 * @deprecated since AUI 7.9.4. Use the `<aui-spinner>` web component directly.
 */
$.fn.spinStop = fn(function spinStop() {
    return this.each(function() {
        if (!this || !this.nodeType) { return; }
        const $this = $(this);
        const data = $this.data();
        if (data && data.spinner) {
            data.spinner.remove();

            delete data.spinner;
        }
    });
}, 'jQuery.fn.spinStop', {
    sinceVersion: '7.9.4',
    removeInVersion: '10.0.0',
    alternativeName: '<aui-spinner>'
});

import createHeader from './internal/header/create-header';
import skate from './internal/skate';

const Header = skate('aui-header-end', {
    type: skate.type.ELEMENT,
    created: function (element) {
        const auiHeader = element.parentElement;
        if (auiHeader && auiHeader.classList.contains('aui-header')) {
            createHeader(auiHeader);
        } else {
            console.log('Could not async. initialise aui-header');
        }
    }
});

export default Header;


function SuggestionsModel () {
    this._suggestions = [];
    this._activeIndex = -1;
}

SuggestionsModel.prototype = {
    onChange: function () {},

    onHighlightChange: function () {},

    get: function (index) {
        return this._suggestions[index];
    },

    set: function (suggestions) {
        var oldSuggestions = this._suggestions;
        this._suggestions = suggestions || [];
        this.onChange(oldSuggestions);
        return this;
    },

    getNumberOfResults: function () {
        return this._suggestions.length;
    },

    setHighlighted: function (toHighlight) {
        if (toHighlight) {
            for (var i = 0; i < this._suggestions.length; i++) {
                if (this._suggestions[i].id === toHighlight.id) {
                    this.highlight(i);
                }
            }
        }

        return this;
    },

    highlight: function (index) {
        this._activeIndex = index;
        this.onHighlightChange();
        return this;
    },

    highlightPrevious: function () {
        const current = this._activeIndex;
        const previousActiveIndex = (current === 0) ? this._suggestions.length - 1 : (current - 1);
        this.highlight(previousActiveIndex);
        return this;
    },

    highlightNext: function () {
        const current = this._activeIndex;
        const nextActiveIndex = (current === this._suggestions.length - 1) ? 0 : (current + 1);
        this.highlight(nextActiveIndex);
        return this;
    },

    highlighted: function () {
        return this.get(this._activeIndex);
    },

    highlightedIndex: function () {
        return this._activeIndex;
    }
};

export default SuggestionsModel;

import $ from './jquery';
import { defaults } from 'underscore';
import { recomputeStyle } from './internal/animation';
import amdify from './internal/amdify';
import globalize from './internal/globalize';
import keyCode from './key-code';
import CustomEvent from './polyfills/custom-event';
import escapeHtml from './escape-html';
import { CLOSE_BUTTON } from './close-button';
import { FOCUSABLE_QUERY } from './internal/a11y/focusable-query';
import getFocusManager from './focus-manager';

const AUTO_CLOSE_TIME = 8000;
const ID_FLAG_CONTAINER = 'aui-flag-container';
const DEFAULT_OPTIONS = {
    body: '',
    close: 'manual',
    title: '',
    type: 'info',
    duration: AUTO_CLOSE_TIME,
    ariaLive: 'polite'
};

function flag (flagOptions) {
    const options = defaults(flagOptions, DEFAULT_OPTIONS);
    options.title = (options.title || '').toString().trim();
    const $flag = renderFlagElement(options);
    extendFlagElement($flag);

    if (options.close === 'auto') {
        makeCloseable($flag);

        if ($flag.attr('aui-focus-trap') === 'false') {
            makeAutoClosable($flag, options.duration);
        }
    } else if (options.close === 'manual') {
        makeCloseable($flag);
    }

    handleFlagContainer();
    insertFlag($flag);
    setTimeout(function () {
        if ($flag.attr('aui-focus-trap') === 'true') {
            getFocusManager.global.enter($flag);
        }
    }, 0);

    setTimeout(function () {
        $flag.attr({
            'aria-hidden': false
        });
    }, 100)

    return $flag.get(0);
}

function extendFlagElement ($flag) {
    const flag = $flag[0];

    flag.close = function () {
        closeFlag($flag);
    };
}

function renderFlagElement ({ body, title, close, type, ariaLive }) {
    const titleHtml = title ? `<p class="title"><strong>${escapeHtml(title)}</strong></p>` : '';
    const html = `<div class="aui-message">${titleHtml}</div>`;
    const ariaLabel = title ? escapeHtml(title) : '';

    const $message = $(html)
        .append($.parseHTML(body || ''))
        .addClass(close === 'never' ? '' : 'closeable')
        .addClass(`aui-message-${type}`);
    const isFocusable = $message.find(FOCUSABLE_QUERY).length > 0;
    const ariaRole = isFocusable ? 'alertdialog' : 'alert';

    return $(`<div aui-focus-trap="${isFocusable}" class="aui-flag" aria-label="${ariaLabel}" aria-hidden="true" aria-live="${escapeHtml(ariaLive)}" role="${ariaRole}"></div>`).append($message);
}

function makeCloseable ($flag) {
    const $icon = $(CLOSE_BUTTON);

    $icon.on('click', function () {
        closeFlag($flag);
    });

    $icon.on('keypress', function (e) {
        if ((e.which === keyCode.ENTER) || (e.which === keyCode.SPACE)) {
            closeFlag($flag);
            e.preventDefault();
        }
    });

    return $flag.find('.aui-message').append($icon)[0];
}

function makeAutoClosable ($flag, duration) {
    $flag.find('.aui-message').addClass('aui-will-close');
    setTimeout(function () {
        $flag[0].close();
    }, duration);
}

function closeFlag ($flagToClose) {
    const flag = $flagToClose.get(0);

    flag.removeAttribute('open');
    flag.setAttribute('inert', '');
    flag.setAttribute('aria-hidden', true);
    flag.dispatchEvent(new CustomEvent('aui-flag-close', { bubbles: true }));
    if ($flagToClose.attr('aui-focus-trap')) {
        getFocusManager.global.exit($flagToClose);
    }

    return flag;
}

function handleFlagContainer () {
    const container = findContainer();

    if (container) {
        const closedFlags = container.querySelectorAll('.aui-flag:not([open])');

        Array.from(closedFlags).map(flag => flag.parentNode.removeChild(flag));
    } else {
        $('body').prepend('<div id="' + ID_FLAG_CONTAINER + '"></div>');
    }
}

function findContainer () {
    return document.getElementById(ID_FLAG_CONTAINER);
}

function insertFlag ($flag) {
    const flagContainer = findContainer();

    $flag.appendTo(flagContainer);
    recomputeStyle($flag);

    $flag
        .removeAttr('hidden')
        .attr('open', '');
}

amdify('aui/flag', flag);
globalize('flag', flag);
export default flag;

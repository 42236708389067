import template from 'skatejs-template-html';

export default template(`
    <input type="text" class="text" role="combobox" aria-expanded="false">
    <select></select>
    <datalist>
        <content select="aui-option"></content>
    </datalist>
    <button class="aui-button" tabindex="-1" type="button"></button>
    <div class="aui-popover" data-aui-alignment="bottom left" hidden>
        <ul role="listbox" class="aui-optionlist"></ul>
    </div>
    <div class="aui-select-status assistive" aria-live="polite" role="status"></div>
`);

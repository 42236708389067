import skate from 'skatejs';
import {createFormsComponentBody} from './create-forms-component-body';


/**
 * Allows us to add a new DOM element for rendering ADG styled checkbox glyphs,
 * so we can get our desired aesthetic without having to rely on a specific markup pattern.
 */
skate(
    'checkbox',
    createFormsComponentBody('checkbox')
);

import globalize from './internal/globalize';
import {getMessageLogger} from './internal/deprecation';

const deprecation = {
    sinceVersion: '9.0.0',
    removeInVersion: '10.0.0'
};

const proxy = new Proxy({}, {
    get: function(obj, prop) {
        getMessageLogger('AJS.params', deprecation)();
        return obj[prop];
    },
})

export default globalize('params', proxy);

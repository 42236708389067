import globalize from './internal/globalize';
import * as deprecate from './internal/deprecation';

/**
 * @deprecated Since 8.0.0 Use equivalent functions from libraries like lodash / underscore instead.
 */
function debounce (func, wait) {
    var timeout;
    var result;

    return function () {
        var args = arguments;
        var context = this;
        var later = function () {
            result = func.apply(context, args);
            context = args = null;
        };

        clearTimeout(timeout);
        timeout = setTimeout(later, wait);

        return result;
    };
}

var deprecatedDebounce = deprecate.fn(debounce, 'AJS.debounce', {
    removeInVersion: '10.0.0',
    sinceVersion: '8.0.0',
    extraInfo: 'Use equivalent functions from libraries like lodash / underscore instead'
});

export default deprecatedDebounce;

globalize('debounce', deprecatedDebounce);

/**
 * @deprecated Since 8.0.0 Use equivalent functions from libraries like lodash / underscore instead.
 */
function debounceImmediate (func, wait) {
    var timeout = null;
    var result;

    return function () {
        var context = this;
        var args = arguments;
        var later = function () {
            timeout = context = args = null;
        };

        if (timeout === null) {
            result = func.apply(context, args);
        }

        clearTimeout(timeout);
        timeout = setTimeout(later, wait);

        return result;
    };
}

var deprecatedDebounceImmediate = deprecate.fn(debounceImmediate, 'AJS.debounceImmediate', {
    removeInVersion: '10.0.0',
    sinceVersion: '8.0.0',
    extraInfo: 'Use equivalent functions from libraries like lodash / underscore instead'
});

export {
    deprecatedDebounceImmediate as debounceImmediate
};

globalize('debounceImmediate', deprecatedDebounceImmediate);

